<template>
  <div
    class="custom-bg bgi-size-cover bgi-position-center"
    :style="{
      backgroundImage: `url(${backgroundImage})`,
    }"
  >
    <div class="custom-card">
      <span class="custom-icon svg-icon svg-icon-9x svg-icon-dark mr-1">
        <inline-svg src="media/svg/icons/Code/Lock-circle.svg"></inline-svg>
      </span>
      <h1 class="text-h1 text-center custom-title">
        Autenticación fallida
      </h1>
      <p class="text-body-1">
        Para acceder al
        <strong>
          Sistema de Gestión Estudiantil
        </strong>
        es necesario que inicies sesión en el
        <strong>
          Sistema de Autenticación CORE.
        </strong>
      </p>
      <a href="https://core.ricaldone.edu.sv" class="custom-btn">Ir a CORE</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Unauthenticated",
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/unauthenticated.svg";
    },
  },
};
</script>

<style scoped>
.custom-bg {
  min-height: 100vh !important;
  min-width: 100vw !important;
  display: flex;
  justify-items: center;
  align-items: center;
}

.custom-card {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  height: 350px;
  width: 350px;
  padding: 16px 24px;
  margin: auto;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.custom-title {
  font-weight: 700;
}

.text-body-1 {
  font-size: 14px;
}

.custom-btn {
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  font-style: normal;
  color: #fff;
  text-decoration: none;
  letter-spacing: 0px;
  padding: 15px 45px 15px 50px;
  display: inline-block;
  background-color: #000;
  border-radius: 5px;
  align-self: flex-start;
  margin-top: 10px;
}

.custom-icon {
  margin-bottom: 20px;
}
</style>
